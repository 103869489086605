import React from 'react';
// Here we are importing a CSS file as a dependency

// styles = {

// };

function Header() {
  return (
    <header class="text-center bg-light">
      <h2>Joshua Bailey</h2>
    </header>
  );
}

export default Header;